import React from 'react';
import logo from './Images/logo_hvid.svg';
import './App.css';
import { toast, Toaster } from 'react-hot-toast';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Velkommen til Kidsjoy Stories.
        </p>
        <p>
          Brug den medsendte QR-kode til at komme ind på produktinformationen for din lejetøjsboks.
        </p>
        <p className='font-bold'>
          Du skal bruge en mobiltelefon til at tilgå siden.
        </p>
        <a
          className="underline"
          href="https://kidsjoy.dk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Til Kidsjoy.dk
        </a>
      </header>
    </div>
  );
}

export default App;
