import React, {  } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Boks1 } from './Boks1';
import LogRocket from 'logrocket';
import { Boks3 } from './Boks3';
import { Toaster } from 'react-hot-toast';
import { Boks2Old } from './Boks2_old';
import { Boks3Old } from './Boks3_old';
import { GoTo } from './GoTo';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const prod = process.env.NODE_ENV === 'production';


if (prod) {
  LogRocket.init('qly5ub/kidsjoy');
}




const router = createBrowserRouter([
  {
    path: "*",
    element: <App />
  },
  {
    path: "/cagbzrixhy",
    element: <GoTo path="/boks1.html" />
  },
  {
    path: "/rerhvzbbng",
    element: <GoTo path="/boks2.html" />
  },
  {
    path: "/dlxdmzneoz", 
    element: <GoTo path="/boks3.html" />
  },
  {
    path: "/rvoifscxmy",
    element: <GoTo path="/montessori.html" />
  },
  {
    path: "/boks2old",
    element: <Boks2Old />
  },
  {
    path: "/boks3old",
    element: <Boks3Old />
  },
 

],);

root.render(
  <React.StrictMode>
    <Toaster />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
