

import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import Stories from 'ssr-react-insta-stories';


export const Boks2Old = () => {
    const navigate = useNavigate();
    const prod = process.env.NODE_ENV === 'production';

    useEffect(() => {
    if (!isMobile && prod) {
        navigate('/');
    }

    }, []);
    
    const stories = [
        {
            url: '/Boks2_old/1.jpg',
            type: 'image',
        },
        {
            url: '/Boks2_old/2.jpg',
            type: 'image',
        },
        {
            url: '/Boks2_old/3.jpg',
            type: 'image',
        },
        {
            url: '/Boks2_old/4.jpg',
            type: 'image',
        },
        {
            url: '/Boks2_old/5.jpg',
            type: 'image',
        },
        {
            url: '/Boks2_old/6.jpg',
            type: 'image',
        },
        {
            url: '/Boks2_old/7.jpg',
            type: 'image',
        },
        {
            url: '/Boks2_old/8.jpg',
            type: 'image',
        },
    ];

    return (
        <Stories
            stories={stories}
            defaultInterval={30000}
            width={'100vw'}
            height={'100vh'}
            storyStyles={{
                width: '100vw',
                height: '100%',
            }}

        />
    );
};