
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
type GoToProps = {
    path: string;
};

export const GoTo = ({ path }:  GoToProps) => {
    const navigate = useNavigate();
    const prod = process.env.NODE_ENV === 'production';

    useEffect(() => {
    if (!isMobile && prod) {
        navigate('/');
    }
    else {
        window.location.href = path;
    }

    }, []);
    return (
        <></>
    );
};